import React from 'react';
import { PropTypes } from 'prop-types';

import AgendaSummary from '../AgendaSummary';

const propTypes = {
    item: PropTypes.object.isRequired
};

const DayView = (props) => {
    const { item } = props;
    const { date, results } = item;

    return (
        <div className="day-container">
            <div className="day-title">
                {date}
            </div>
            {results.map((agenda, index) => (
                <AgendaSummary {...agenda} key={index} />
            ))}
        </div>
    );
}

DayView.propTypes = propTypes;

export default DayView;