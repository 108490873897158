import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import { Provider as ProviderUI, themes } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import { initializeIcons } from '@uifabric/icons';

import App from './components/App';
import rootReducer from './redux/reducers/rootReducer';
import Loader from './components/Loader';

import './i18n';

function render() {
  const loggerMiddelware = createLogger();
  const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddelware));
  initializeIcons();
  
  ReactDOM.render(
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <ProviderUI theme={themes.teams}>
          <BrowserRouter>
              <Switch>
                  <App />
              </Switch>
          </BrowserRouter>
        </ProviderUI>
      </Provider>
    </Suspense>,
    document.getElementById('root')
  );
}

try {
    microsoftTeams.initialize();

    render();
}
catch(e){
    console.log(e);
}