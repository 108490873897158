import React from 'react';
import PropTypes from 'prop-types';
import { FilesEmptyIcon } from '@fluentui/react-icons-northstar';

import { MenuOptions } from '../../models/MenuOptions';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    menuItem: PropTypes.object.isRequired
};

const defineClassNames = (menuItem) => {
    const { type, isActive, showAnnotationsIcon, showSharedAnnotationsIcon } = menuItem;

    let classNames = [];
    let documentIconClassName = '';

    switch(type) {
        case MenuOptions.DATE:
        case MenuOptions.YEAR:
            classNames.push('date');
            break;
        case MenuOptions.AGENDAITEM:
            classNames.push('agendaitem');
            break;
        case MenuOptions.DOCUMENT:
            classNames.push('document');

            if(showAnnotationsIcon && showSharedAnnotationsIcon) {
                documentIconClassName = 'trailing-icon-2';
            } else if(showAnnotationsIcon || showSharedAnnotationsIcon) {
                documentIconClassName = 'trailing-icon-1';
            }
            break;
        default:
            break;
    }

    if(isActive) {
        classNames.push('active');
    }

    return {
        mainClassName: classNames.join(' '),
        documentIconClassName: documentIconClassName
    }
}

const MenuItem = (props) => {
    const { menuItem, onClick } = props;
    const { title, subTitle, hasSubTitle, type, showAnnotationsIcon, showSharedAnnotationsIcon } = menuItem;

    const menuItemClicked = (e) => {
        e.stopPropagation();

        onClick(menuItem);
    }

    const { mainClassName, documentIconClassName } = defineClassNames(menuItem);

    return (
        <li className='menuItem'
            onClick={menuItemClicked}>
            <div className={mainClassName}>
                {type === MenuOptions.DOCUMENT && (
                    <div>
                        <FilesEmptyIcon outline />
                        <span className={`document-title ${documentIconClassName}`}>
                            {title}
                        </span>
                        {showAnnotationsIcon && (
                            <span className='annotation-icon'></span>
                        )}
                        {showSharedAnnotationsIcon && (
                            <span className='annotation-shared-icon'></span>
                        )}
                    </div>
                )}
                {type !== MenuOptions.DOCUMENT && (
                    <React.Fragment>
                        {hasSubTitle ? (
                            <div className='has-subtitle'>
                                <div>{title}</div>
                                <div className='subtitle'>{subTitle}</div>
                            </div>
                        ) : (
                            <div>{title}</div>

                        )}
                    </React.Fragment>
                )}
            </div>
        </li>
    );
}

MenuItem.propTypes = propTypes;

export default MenuItem;