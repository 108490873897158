import { SET_BREADCRUMBITEMS } from '../actions/actionTypes';

export default initialState => (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case SET_BREADCRUMBITEMS: 
            return {
                ...state,
                breadcrumbItems: payload
            };
        default: 
            return state;
    }
}