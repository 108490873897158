import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import AgendaHeader from '../AgendaHeader';
import Documents from '../Documents';
import AgendaItems from '../AgendaItems';
import { fetchAgenda } from '../../redux/actions/contentActions';

import './Agenda.scss';

class AgendaView extends PureComponent {
    componentDidMount() {
        const { match, getAgenda } = this.props;

        getAgenda(match.params.id);
    }

    componentDidUpdate(prevProps) {
        const { loading, match, getAgenda } = this.props;

        if(loading) {
            return;
        }

        if((prevProps.match.params.id !== match.params.id)){
            getAgenda(match.params.id);
        }
    }

    render() {
        const { loading, agenda, t } = this.props;

        return (
            <div className="agenda">
                {loading ? (<div className="spacing">{t('app.loading')}</div>) : (
                    <React.Fragment>
                        {agenda.id !== undefined ? 
                            (
                                <React.Fragment>
                                    <AgendaHeader agenda={agenda} />
                                    {agenda.documents && <Documents title={t('agenda.documents')} documents={agenda.documents} />}
                                    {agenda.agendaItems && <AgendaItems items={agenda.agendaItems} agendaId={agenda.id} canGetMinutes={agenda.canGetAgendaItemMinutes} /> }
                                </React.Fragment>
                            ) : (
                                <div className="spacing">{t('app.noMeeting')}</div>
                            )
                        }
                    </React.Fragment>
                )}
            </div>
        );
    }
}

AgendaView.propTypes = {
    loading: PropTypes.bool.isRequired,
    agenda: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getAgenda: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { loading, agenda } = state.content;

    return {
        loading,
        agenda
    }
}

function mapDispatchToProps(dispatch) {    
    return {
        getAgenda: (agendaId) => { dispatch(fetchAgenda(agendaId)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AgendaView)));