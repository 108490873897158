import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
    url: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

const BreadcrumbItem = (props) => {
    const { url, title, onClick } = props;

    return (
        <div className='breadcrumb-item'>
            {url === '' ? (
                <div className='link' onClick={onClick}>
                    {title}
                </div>
            ) : (
                <Link to={url}>{title}</Link>
            )}
        </div>
    );
}

BreadcrumbItem.propTypes = propTypes;

export default BreadcrumbItem;