import React from 'react';
import { PropTypes } from 'prop-types';

import DocumentInfo from '../DocumentInfo';

const propTypes = {
    title: PropTypes.string.isRequired,
    documents: PropTypes.array.isRequired
};

const Documents = (props) => {
    const { title, documents } = props;

    return (
        <>
            {documents && documents.length > 0 && (
                <div className="documents">
                    {title !== undefined && <div className="title">{title}</div>}
                    {documents.map((document, index) => (
                        <DocumentInfo document={document} key={index} />
                    ))}
                </div>
            )}
        </>
    );
}

Documents.propTypes = propTypes;

export default Documents;