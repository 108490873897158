import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { fetchDocument, clearDocument } from '../../redux/actions/contentActions';

import PdfWebViewer from '../PdfWebViewer';

import './DocumentView.scss';

const propTypes = {
    loading: PropTypes.bool.isRequired,
    document: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getDocument: PropTypes.func.isRequired,
    setBreadcrumbItem: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

class DocumentView extends PureComponent {
    componentDidMount(){
        const { match, getDocument } = this.props;

        getDocument(match.params.id);
    }

    componentDidUpdate(prevProps) {
        const { loading, document, match, getDocument } = this.props;

        if(loading) {
            return;
        }

        if(prevProps.match.params.id !== match.params.id){
            getDocument(match.params.id);
        }
    }

    componentWillUnmount() {
        const { resetDocument } = this.props;
        resetDocument();
    }

    render() {
        return (
            <div className="document-view">
                <PdfWebViewer />
            </div>
        );
    }
}

DocumentView.propTypes = propTypes;

function mapStateToProps(state) {
    const { loading, document } = state.content;

    return {
        loading,
        document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocument: (documentId) => {dispatch(fetchDocument(documentId))},
        resetDocument: () => { dispatch(clearDocument()) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentView)));