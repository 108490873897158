import { SET_ACTIVE_MENU, SET_BACKBUTTON, SET_MENUITEMS, SET_SEARCHVALUE, SET_MENU_PARAMETERS } from '../actions/actionTypes';

export default initialState => (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case SET_ACTIVE_MENU: 
            return {
                ...state,
                activeMenu: payload
            };
        case SET_BACKBUTTON: 
            return {
                ...state,
                backButton: payload
            };
        case SET_MENUITEMS: 
            return {
                ...state,
                menuItems: payload
            };
        case SET_SEARCHVALUE: 
            return {
                ...state,
                searchValue: payload
            };
        case SET_MENU_PARAMETERS:
            return {
                ...state,
                parameters: payload
            };
        default: 
            return state;
    }
}