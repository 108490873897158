import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { ChevronDownIcon, ChevronEndIcon } from '@fluentui/react-icons-northstar';

import { saveAgendaItemNote, deleteAgendaItemNote } from '../../redux/actions/contentActions';

import ModalPopup from '../ModalPopup';

const AgendaItemTitle = (props) => {
    const { item, expanded, noteSaving, titleClick, t } = props;
    const noteRef = useRef();
    const [noteShown, setNoteShown] = useState(false);
    const [sharedNotesShown, setSharedNotesShown] = useState(false);

    const noteIconClicked = (e) => {
        e.stopPropagation();

        setNoteShown(true);
    }

    const notePopupClosed = () => {
        setNoteShown(false);
    }

    const noteSaveClicked = () => {
        const { item, saveNote } = props;

        if(noteRef.current) {
            var value = noteRef.current.value;

            saveNote(item.originalId, value);
        }
    }

    const noteDeleteClicked = () => {
        const { item, deleteNote } = props;

        deleteNote(item.originalId);
    }

    const sharedNotesIconClicked = (e) => {
        e.stopPropagation();

        setSharedNotesShown(true);
    }

    const sharedNotesPopupClosed = () => {
        setSharedNotesShown(false);
    }

    const buttons = {
        button1: {
            visible: true,
            text: t('app.cancel'),
            onClick: notePopupClosed
        },
        button3: {
            visible: true,
            text: t('app.save'),
            onClick: noteSaveClicked
        }
    };

    if(item.hasNote) {
        buttons.button2 = {
            visible: true,
            text: t('app.delete'),
            onClick: noteDeleteClicked
        }
    }

    useEffect(() => {
        if(noteSaving === false) {
            notePopupClosed();
        }
    }, [noteSaving]);

    return (
        <>
            <div className="title" onClick={titleClick}>
                {expanded ? (<ChevronDownIcon />) : (<ChevronEndIcon />)}
                <div className="title-inner">
                    {item.title}
                </div>
                <FontIcon iconName={item.hasNote ? "CannedChat" : "Chat"} className="ms-IconAgendaItem" onClick={noteIconClicked} />
                {item.hasSharedNotes && (
                    <FontIcon iconName="ChatInviteFriend" className="ms-IconAgendaItem" onClick={sharedNotesIconClicked} />
                )}
            </div>
            <ModalPopup isOpen={noteShown} title={t('app.notes')} onClose={notePopupClosed} {...buttons}>
                <Form.Control as="textarea" rows="4" ref={noteRef}>
                    {item.note}
                </Form.Control>
            </ModalPopup>
            {item.hasSharedNotes && (
                <ModalPopup isOpen={sharedNotesShown} title={t('agenda.items.sharedNotesTitle')} onClose={sharedNotesPopupClosed}>
                    {item.sharedNotes.map((sharedNote, index) => (
                        <div className="shared-note">
                            <div className="shared-note-user">{sharedNote.userName}</div>
                            <div>{sharedNote.note}</div>
                        </div>
                    ))}
                </ModalPopup>
            )}
        </>
    );
}

function mapStateToProps(state) {
    const { noteSaving } = state.content;

    return {
        noteSaving
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveNote: (agendaItemId, note) => { dispatch(saveAgendaItemNote(agendaItemId, note)) },
        deleteNote: (agendaItemId) => { dispatch(deleteAgendaItemNote(agendaItemId)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AgendaItemTitle));