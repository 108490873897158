export const SET_BACKBUTTON = 'SET_BACKBUTTON';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_MENUITEMS = 'SET_MENUITEMS';
export const SET_SEARCHVALUE = 'SET_SEARCHVALUE';
export const SET_BREADCRUMBITEMS = 'SET_BREADCRUMBITEMS';
export const SET_MENU_PARAMETERS = 'SET_MENU_PARAMETERS';
export const CONTENT_LOADING = 'CONTENT_LOADING';
export const SET_AGENDA_LIST = 'SET_AGENDA_LIST';
export const SET_AGENDA = 'SET_AGENDA';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_NOTE_SAVING = 'SET_NOTE_SAVING';
export const ISAUTHENTICATING = 'ISAUTHENTICATING';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_AUTHENTICATE_ERROR_MESSAGE = 'SET_AUTHENTICATE_ERROR_MESSAGE';