import { hot } from 'react-hot-loader/root';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router';
import { Input } from '@fluentui/react-northstar';
import { SearchIcon } from '@fluentui/react-icons-northstar';

import { isAuthenticated } from '../../redux/actions/authenticateActions';
import { doSearch } from '../../redux/actions/menuActions';

import './App.scss';

import Menu from '../Menu';
import AgendaListView from '../AgendaListView';
import AgendaView from '../AgendaView';
import DocumentView from '../DocumentView';
import Breadcrumb from '../Breadcrumb';
import WelcomeView from '../WelcomeView';
import ActionLink from '../ActionLink';

class App extends PureComponent {
    constructor(props) {
        super(props);

        this.searchKeyDown = this.searchKeyDown.bind(this);
    }

    componentDidMount() {
        const { checkAuthenticated } = this.props;

        checkAuthenticated();
    }

    searchKeyDown(e) {
        const { search } = this.props;
        if (e.keyCode !== 13) {
            return;
        }
        e.preventDefault();

        search(e.currentTarget.value.trim());
    }

    render() {
        const { isAuthenticating, authenticated, t } = this.props;

        return (
            <React.Fragment>
                {isAuthenticating && (<div>Authenticating....</div>)}
                {isAuthenticating === false && (
                    <React.Fragment>
                        {authenticated && (
                            <React.Fragment>
                                <header>
                                    <aside>
                                        <div className="search-container">
                                            <Input icon={<SearchIcon />} fluid clearable placeholder={t('search.placeholder')} onKeyDown={this.searchKeyDown} />
                                        </div>
                                    </aside>
                                    <section>
                                        <div className="content-container">
                                            <Breadcrumb />
                                            <div className="actions">
                                                <ActionLink link="/api/Authenticate/Logout" text={t("app.logout")} />
                                            </div>
                                        </div>
                                    </section>
                                </header>
                                <div className="content">
                                    <Menu />
                                    <section>
                                        <div className="content-container">
                                            <Route path="/"
                                                exact
                                                component={AgendaListView}/>
                                            <Route path="/agenda/:id"
                                                    component={AgendaView} />
                                            <Route path="/document/:id"
                                                    component={DocumentView} />
                                        </div>
                                    </section>
                                </div>
                            </React.Fragment>
                        )}
                        {authenticated === false && (
                            <WelcomeView />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
};

function mapStateToProps(state) {
    const { isAuthenticating, authenticated } = state.authenticate;

    return {
        isAuthenticating,
        authenticated
    }
}

function mapDispatchToProps(dispatch) {
    return {
        checkAuthenticated: () => { dispatch(isAuthenticated()); },
        search: (searchTerm) => { dispatch(doSearch(searchTerm)); }
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App))));