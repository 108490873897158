import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MenuItem from '../MenuItem';
import BackButton from '../BackButton';
import { retrieveMenuItems, menuNavigation, updateActiveMenuItem, setDocumentMenu } from '../../redux/actions/menuActions';

import './Menu.scss';

class Menu extends PureComponent {
    constructor(props) {
        super(props);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
    }

    componentDidMount() {
        const { fetchMainMenu } = this.props;

        fetchMainMenu();
    }

    componentDidUpdate(prevProps) {
        const { agenda, document, setActiveMenu, setMenuForDocument } = this.props;
        if(prevProps.agenda.id !== agenda.id) {
            setActiveMenu(agenda.id);
        }
        if(prevProps.document.id !== document.id) {
            setMenuForDocument();
        }
    }

    onMenuItemClick(menuItem) {
        this.navigate(menuItem);
    }

    onBackButtonClick() {
        this.navigate(null);
    }

    navigate(menuItem) {
        const { performMenuNavigation, history } = this.props;

        performMenuNavigation(menuItem, history);
    }

    render () {
        const { menuItems, backButton } = this.props;

        return (
            <aside className="menu">
                {backButton.show && (
                    <BackButton text={backButton.text} onClick={this.onBackButtonClick} />
                )}
                <ul className="menuList">
                    {menuItems.map((menuItem, index) => (
                        <MenuItem key={index} menuItem={menuItem} onClick={this.onMenuItemClick} />
                    ))}
                </ul>
            </aside>
        )
    };
}

Menu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    backButton: PropTypes.object.isRequired,
    fetchMenuItems: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { menuItems, backButton } = state.menu;
    const { agenda, document } = state.content;

    return {
        backButton,
        menuItems,
        agenda,
        document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMainMenu: () => {dispatch(retrieveMenuItems())},
        performMenuNavigation: (clickedMenuItem, history) => { dispatch(menuNavigation(clickedMenuItem, history)) },
        setActiveMenu: (id) => { dispatch(updateActiveMenuItem(id)) },
        setMenuForDocument: () => { dispatch(setDocumentMenu()) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));