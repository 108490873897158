export default (addEvent, listener) => {
    const webviewerContainerIframe = document.querySelector("#webviewer-container > iframe");
    if (webviewerContainerIframe && webviewerContainerIframe.contentWindow) {
        const iframeDocument = webviewerContainerIframe.contentWindow.document;
        if (addEvent) {
            iframeDocument.body.addEventListener('click', listener);
        } else {
            iframeDocument.body.removeEventListener('click', listener);
        }
    }
}