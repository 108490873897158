import { API_URL } from '../../constants/settings';
import { CONTENT_LOADING, SET_AGENDA_LIST, SET_AGENDA, SET_DOCUMENT, SET_NOTE_SAVING } from './actionTypes';

export function contentLoading(loading) {
    return { type: CONTENT_LOADING, payload: loading }
}

export function setAgendaList(agendas) {
    return { type: SET_AGENDA_LIST, payload: agendas }
}

export function setAgenda(agenda) {
    return { type: SET_AGENDA, payload: agenda }
}

export function setDocument(document) {
    return { type: SET_DOCUMENT, payload: document }
}

export function setNoteSaving(isSaving) {
    return { type: SET_NOTE_SAVING, payload: isSaving }
}

export function fetchAgendaList() {
    return dispatch => {
        dispatch(contentLoading(true));
        return fetch(`${API_URL}Agenda/List`)
            .then(response => response.json())
            .then(json => {
                if(json.data) {
                    dispatch(setAgendaList(json.data));
                }
                dispatch(contentLoading(false));
            });
    }
}

export function fetchAgenda(agendaId) {
    return dispatch => {
        dispatch(contentLoading(true));
        return fetch(`${API_URL}Agenda/${agendaId}`)
            .then(response => response.json())
            .then(json => {
                if(json.data) {
                    dispatch(setAgenda(json.data));
                }
                dispatch(contentLoading(false));
            });
    }
}

export function fetchDocument(documentId) {
    return dispatch => {
        dispatch(contentLoading(true));
        return fetch(`${API_URL}Document/${documentId}`)
            .then(response => response.json())
            .then(json => {                
                if(json.data) {
                    dispatch(setDocument(json.data));
                }
                dispatch(contentLoading(false));
            });
    }
}

export function clearDocument() {
    return dispatch => {
        dispatch(setDocument({}));
    }
}

export function clearAgenda() {
    return dispatch => {
        dispatch(setAgenda({}));
    }
}

export function saveAgendaItemNote(agendaItemId, note) {
    return (dispatch, getState) => {
        const state = getState();
        const { agenda } = state.content;

        dispatch(setNoteSaving(true));
        const data = {
            AgendaId: agenda.id,
            AgendaItemId: agendaItemId,
            Note: note
        }

        return fetch(`${API_URL}AgendaItem/SaveNote`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(json => {
                    if(json.data.success) {
                        var newAgenda = { ...agenda };
                        var agendaItem = newAgenda.agendaItems.find(item => {
                            return item.originalId == agendaItemId;
                        });

                        agendaItem.note = note;
                        agendaItem.hasNote = json.data.hasNote;

                        dispatch(setNoteSaving(false));
                        dispatch(setAgenda(newAgenda));
                    }
                });
    }
}

export function deleteAgendaItemNote(agendaItemId) {
    return (dispatch, getState) => {
        const state = getState();
        const { agenda } = state.content;

        dispatch(setNoteSaving(true));

        return fetch(`${API_URL}AgendaItem/DeleteNote/${agenda.id}/${agendaItemId}`, {
                    method: 'DELETE'
                })
                .then(response => response.json())
                .then(json => {
                    if(json.data.success) {
                        var newAgenda = { ...agenda };
                        var agendaItem = newAgenda.agendaItems.find(item => {
                            return item.originalId == agendaItemId;
                        });

                        agendaItem.note = "";
                        agendaItem.hasNote = false;

                        dispatch(setNoteSaving(false));
                        dispatch(setAgenda(newAgenda));
                    }
                });
    }
}