import { SET_BREADCRUMBITEMS } from './actionTypes';
import { BreadcrumbItemType } from '../../models/BreadcrumbItemType';

function NOOP() {}

export function setBreadcrumbItems(items) {
    return { type: SET_BREADCRUMBITEMS, payload: items };
}

export function addBreadcrumbItem(id, title, type) {
    return (dispatch, getState) => {
        const state = getState();
        const { breadcrumbItems } = state.breadcrumb;
        var newBreadcrumbs = [...breadcrumbItems];
        const index = newBreadcrumbs.findIndex(item => {
            return item.type === type;
        });

        const breadcrumbItem = {
            id: id,
            title: title,
            type: type,
            url: '',
            onClick: NOOP
        }

        switch (type) {
            case BreadcrumbItemType.AGENDATYPE: 
                break;
            case BreadcrumbItemType.YEAR: 
                break;
            case BreadcrumbItemType.AGENDA: 
                breadcrumbItem.url = `/agenda/${id}`;
                break;
            case BreadcrumbItemType.DOCUMENT:
                breadcrumbItem.url = `/document/${id}`;
                break;
            default:
                return;
        }

        if(index > -1) {
            newBreadcrumbs[index] = breadcrumbItem;
        } else {
            newBreadcrumbs.push(breadcrumbItem);
        }

        return dispatch(setBreadcrumbItems(newBreadcrumbs));
    }
}

export function removeBreadcrumbItem(type) {
    return (dispatch, getState) => {
        const state = getState();
        const { breadcrumbItems } = state.breadcrumb;
        var newBreadcrumbs = breadcrumbItems.filter(item => {
            return item.type !== type;
        });

        return dispatch(setBreadcrumbItems(newBreadcrumbs));
    }
}