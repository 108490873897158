import React from 'react';
import ReactMarkdown from 'react-markdown';

import remarkInsert from '../../remark/underline';

const MarkdownViewer = (props) => {
    const { text } = props;

    const renderers = {
        insert: 'ins'
    };

    const plugins = [remarkInsert];

    return (
        <>
            {text !== undefined && (
                <ReactMarkdown source={text} renderers={renderers} plugins={plugins} {...props} />
            )}
        </>
    )
}

export default MarkdownViewer;