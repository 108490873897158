import { combineReducers } from 'redux';

import menuReducer from './menuReducer';
import breadcrumbReducer from './breadcrumbReducer';
import contentReducer from './contentReducer';
import authenticateReducer from './authenticateReducer';
import initialState from '../initialState';

const rootReducer = combineReducers({
    menu: menuReducer(initialState.menu),
    breadcrumb: breadcrumbReducer(initialState.breadcrumb),
    content: contentReducer(initialState.content),
    authenticate: authenticateReducer(initialState.authenticate)
});

export default rootReducer;