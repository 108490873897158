import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button } from '@fluentui/react-northstar';
import { ChevronStartIcon } from '@fluentui/react-icons-northstar';

const propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

const BackButton = (props) => {
    const { onClick, text, t } = props;

    const translatedText = t(text);

    return (
        <div className="backButtonContainer" onClick={onClick}>
            <Button icon={<ChevronStartIcon />} text iconOnly title="Back" />
            <div className="backButtonText">{translatedText}</div>
        </div>
    );
}

BackButton.propTypes = propTypes;

export default withTranslation()(BackButton);