import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import * as microsoftTeams from '@microsoft/teams-js';

import { setAuthenticated, setAuthenticateErrorMessage } from '../../redux/actions/authenticateActions';

import './WelcomeView.scss';

const propTypes = {
    errorMessage: PropTypes.string,
    setIsAuthenticated: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

class WelcomeView extends PureComponent {
    constructor(props) {
        super(props);

        this.login = this.login.bind(this);
    }

    login() {
        const { t, setIsAuthenticated, setErrorMessage } = this.props;
        setErrorMessage('');

        microsoftTeams.authentication.authenticate({
            url: "/authenticate.html",
            width: 900,
            height: 730,
            successCallback: function () {
                setIsAuthenticated(true);
            },
            failureCallback: function (reason) {
                microsoftTeams.getContext(function (context) {
                    setIsAuthenticated(false);
    
                    let error = "";
                    switch(reason) {
                        case "CancelledByUser":
                            break;
                        case "UsernameMismatch":
                            error = t("welcome.errors.usernameMismatch");
                            break;
                        case "StateDoesNotMatch":
                        case "UnexpectedFailure":
                        default:
                            error = t("welcome.errors.unexpectedFailure");
                            break;
                    }
    
                    setErrorMessage(error);
                });
            }
        });
    }

    render() {
        const { errorMessage, t } = this.props;

        return (
            <div className="app-container">
                <div className="column logo">
                    <div className="header">
                        <img src='/images/WideLogo.png' alt={t('welcome.logoAltText')} />
                    </div>
                    <img src='/images/Lines2.png' alt={t('welcome.focusAltText')} />
                </div>
                <div className="column info">
                    <div className="header">
                        <h1>{t('welcome.header')}</h1>
                        <p>{t('welcome.loginText')}</p>
                        <p>
                            {t('welcome.noAccountText')}<br />
                            <a href="https://www.ibabs.eu/nl/#klaar-voor-ibabs" target="_blank" rel="noopener noreferrer">{t('welcome.linkText')}</a>
                        </p>
                        <p className="error-message">
                            {errorMessage}
                        </p>
                        <div className="button-container">
                            <button type="button" onClick={this.login}>{t('welcome.buttonText')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

WelcomeView.propTypes = propTypes;

function mapStateToProps(state) {
    const { errorMessage } = state.authenticate;

    return {
        errorMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setIsAuthenticated: (authenticated) => { dispatch(setAuthenticated(authenticated)); },
        setErrorMessage: (message) => { dispatch(setAuthenticateErrorMessage(message)); }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WelcomeView)));