import tokenizeUnderline from './tokenize/insert';

export default function plugin() {
    var Parser = this.Parser;
    var tokenizers = Parser.prototype.inlineTokenizers;
    var methods = Parser.prototype.inlineMethods;
  
    // Add an inline tokenizer (defined in the following example).
    tokenizers.insert = tokenizeUnderline;
  
    // Run it just before `text`.
    methods.splice(methods.indexOf('text'), 0, 'insert');
}