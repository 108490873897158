import React, { PureComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@fluentui/react-northstar';
import { PropTypes } from 'prop-types';

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    button1: PropTypes.object,
    button2: PropTypes.object,
    button3: PropTypes.object,
    onClose: PropTypes.func.isRequired
}

class ModalPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        const { onClose } = this.props;

        onClose();
    }

    render() {
        const { isOpen, title, button1, button2, button3, children } = this.props;

        return (
            <Modal show={isOpen} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    {button1 && button1.visible && (
                        <Button secondary onClick={button1.onClick}>
                            {button1.text}
                        </Button>
                    )}
                    {button2 && button2.visible && (
                        <Button secondary onClick={button2.onClick}>
                            {button2.text}
                        </Button>
                    )}
                    {button3 && button3.visible && (
                        <Button primary onClick={button3.onClick}>
                            {button3.text}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalPopup.propTypes = propTypes;

export default ModalPopup;