import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import MarkdownViewer from '../MarkdownViewer';

const propTypes = {
    id: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string
};

const AgendaSummary = (props) => {
    const { id, time, title, description } = props;
    const agendaUrl = `/agenda/${id}`;

    return (
        <Link to={agendaUrl} className="agenda-container">
            <div className="agenda-time">
                {time}
            </div>
            <div className="agenda-summary">
                <div className="agenda-title">
                    {title}
                </div>
                <MarkdownViewer text={description} className="agenda-description" />
                <div className="agenda-documents">

                </div>
            </div>
        </Link>
    );
}

AgendaSummary.propTypes = propTypes;

export default AgendaSummary;