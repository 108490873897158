import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import AgendaItem from '../AgendaItem';

const propTypes = {
    items: PropTypes.array.isRequired,
    agendaId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
};

const AgendaItems = (props) => {
    const { items, agendaId, canGetMinutes, t } = props;

    return (
        <div className="agenda-items">
            <div className="title">
                {t('agenda.items.title')}
            </div>
            {items.map((agendaItem, index) => (
                <AgendaItem item={agendaItem} agendaId={agendaId} key={index} canGetMinutes={canGetMinutes} />
            ))}
        </div>
    );
}

AgendaItems.propTypes = propTypes;

export default withTranslation()(AgendaItems);