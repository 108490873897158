import React from 'react';
import { withRouter } from 'react-router';
import { FilesEmptyIcon } from '@fluentui/react-icons-northstar';
import { PropTypes } from 'prop-types';

const propTypes = {
    history: PropTypes.object.isRequired,
    document: PropTypes.object.isRequired
};

const DocumentInfo = (props) => {
    const { document } = props;
    const { title, hasAnnotations, hasSharedAnnotations } = document;
    
    const openDocument = (e) => {
        const { history, document } = props;

        e.stopPropagation();
        history.push(`/document/${document.id}`);
    }

    return (
        <div className="document-container" onClick={openDocument}>
            <FilesEmptyIcon outline />
            {title}
            <div className="annotation-container">
                {hasAnnotations && <div className="icon annotation-icon"></div>}
                {hasSharedAnnotations && <div className="icon annotation-shared-icon"></div>}
            </div>
        </div>
    );
}

DocumentInfo.propTypes = propTypes;

export default withRouter(DocumentInfo);