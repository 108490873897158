import React from 'react';
import { Checkbox } from '@fluentui/react-northstar';

import './SelectionButton.scss';

class SelectionButton extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        }
    }

    onButtonClick = (e) => {
        e.preventDefault();

        const newState = !this.state.selected;
        this.handleChange(newState);
    }

    handleChange = (selected) => {
        this.setState({
            selected: selected
        });
        const { label, onClick } = this.props;

        onClick(label, selected);
    }

    render() {
        const { label, color } = this.props;
        const { selected } = this.state;

        const colorStyle = {
            backgroundColor: `${color}`
        };

        return (
            <div className="Button SelectionButton" onClick={this.onButtonClick}>
                {color !== null && (
                    <div className="user-color" style={colorStyle}></div>
                )}
                {label}
                <Checkbox checked={selected} />
            </div>
        );
    }
}

export default SelectionButton;