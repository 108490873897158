import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import Documents from '../Documents';
import MarkdownViewer from '../MarkdownViewer';
import AgendaItemTitle from '../AgendaItemTitle';
import AgendaItemMinutes from '../AgendaItemMinutes';
import ActionItems from '../ActionItems';

import getClassName from '../../helpers/getClassName';

const propTypes = {
    item: PropTypes.object.isRequired,
    agendaId: PropTypes.string.isRequired,
    canGetMinutes: PropTypes.bool.isRequired
}

const AgendaItem = (props) => {
    const { item, agendaId, canGetMinutes } = props;
    const [expanded, setExpanded] = useState(false);

    const agendaItemTitleClick = (e) => {
        e.stopPropagation();

        setExpanded(!expanded);
    }

    return (
        <div className="agenda-item-container">
            <AgendaItemTitle item={item} expanded={expanded} titleClick={agendaItemTitleClick} />
            <div className="agenda-item-content">
                <MarkdownViewer text={item.description} className="description" />
                <Documents documents={item.documents} />
                {canGetMinutes && (
                    <AgendaItemMinutes id={item.id} />
                )}
                <div className={getClassName("agenda-item-extra-content", { isOpen: expanded })}>
                    {item.numberOfActionItems > 0 && (
                        <ActionItems agendaId={agendaId} agendaItemId={item.id} />
                    )}
                </div>
            </div>
        </div>
    );
}

AgendaItem.propTypes = propTypes;

export default AgendaItem;