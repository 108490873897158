import React from 'react';
import { PropTypes } from 'prop-types';

import MarkdownViewer from '../MarkdownViewer';

const propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isFormatted: PropTypes.bool.isRequired
};

const AgendaHeaderProperty = (props) => {
    const { label, value, isFormatted } = props;

    return (
        <div className="agenda-property">
        <div className="agenda-property-label">{label}</div>
        {isFormatted ? (
            <MarkdownViewer text={value} className="agenda-property-value" />
        ) : (
            <div className="agenda-property-value">
                {value}
            </div>
        )}
    </div>
    );
}

AgendaHeaderProperty.propTypes = propTypes;

export default AgendaHeaderProperty;