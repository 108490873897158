import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/webviewer';

import SharedAnnotationsOverlay from '../SharedAnnotationsOverlay';

import { PDFTronLicense } from '../../constants/settings';

const propTypes = {
    loading: PropTypes.bool.isRequired,
    document: PropTypes.object.isRequired
};

class PdfWebViewer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.viewer = React.createRef();
        this.sharedAnnotationsClicked = this.sharedAnnotationsClicked.bind(this);
        this.updateShownAnnotations = this.updateShownAnnotations.bind(this);

        this.state = {
            instance: null,
            sharedAnnotationsOverlayVisible: false,
            annotationUsers: []
        }
    }

    componentDidUpdate(prevProps) {
        const { loading, document } = this.props;

        if(loading) {
            return;
        }

        if(document.id && prevProps.loading) {
            if(this.state.instance === null) {
                WebViewer({
                    path: '/lib',
                    licenseKey: PDFTronLicense,
                    initialDoc: document.streamUrl,
                    annotationUser: document.annotationUser,
                    css: '/pdftron.css',
                    extension: 'pdf',
                    disabledElements: [
                        'sharedAnnotationsButton'
                    ]
                },
                this.viewer.current).then((instance) => {
                    this.setState({instance: instance});
        
                    var FitMode = instance.FitMode;
                    instance.setFitMode(FitMode.FitWidth);
        
                    instance.setHeaderItems((header) => {
                        header.delete("viewControlsButton");
                        header.delete("panToolButton");
                        header.delete("signatureToolButton");
                        header.delete("shapeToolGroupButton");
                        header.delete("eraserToolButton");
                        header.delete("miscToolGroupButton");
                        
                        var headerItems = header.getItems();
                        var divider = headerItems[1];
        
                        var searchButton = headerItems.splice(16, 1);
                        var stickyToolButton = headerItems.splice(13, 1);
                        var freeTextToolButton = headerItems.splice(12, 1);
                        var textToolGroupButton = headerItems.splice(10, 1);
                        var freeHandToolGroupButton = headerItems.splice(9, 1);
                        var zoomButtons = headerItems.splice(4, 3);
                        headerItems.splice(4, 0, freeTextToolButton[0]);
                        headerItems.splice(4, 0, freeHandToolGroupButton[0]);
                        headerItems.splice(4, 0, stickyToolButton[0]);
                        headerItems.splice(4, 0, textToolGroupButton[0]);
                        headerItems.splice(8, 0, divider);
                        headerItems.splice(9, 0, searchButton[0]);
                        headerItems.splice(14, 0, zoomButtons[1]);
                        headerItems.splice(14, 0, zoomButtons[2]);
                        headerItems.splice(14, 0, zoomButtons[0]);
        
                        const sharedAnnotationsActionButton = {
                            type: 'actionButton',
                            dataElement: 'sharedAnnotationsButton',
                            title: 'Shared Annotations',
                            img: '../../images/shared.png',
                            onClick: this.sharedAnnotationsClicked
                        }
                        headerItems.splice(9, 0, sharedAnnotationsActionButton);
                        headerItems.splice(10, 0, divider);
        
                        header.update(headerItems);
                    });
        
                    var docViewer = instance.docViewer;
                    var annotationManager = docViewer.getAnnotationManager();
                    docViewer.on('documentLoaded', () => {
                        this.documentLoaded();
                    });
        
                    annotationManager.on('annotationChanged', (annotations, action, event) => {
                        this.annotationsChanged(instance, annotations, action, event);
                    });
                });
            } else {
                const { instance } = this.state;

                this.setState({sharedAnnotationsOverlayVisible: false});
                instance.disableElements(['sharedAnnotationsButton']);
                instance.closeDocument();
                instance.annotManager.setCurrentUser(document.annotationUser);
                instance.loadDocument(document.streamUrl,
                    {
                        documentId: document.id,
                        filename: document.title,
                        customHeaders: {},
                        withCredentials: false,
                        cacheKey: "",
                        password: "",
                        extension: 'pdf'
                    });
            }
        }
    }

    documentLoaded() {
        const { document } = this.props;
        const { instance } = this.state;

        fetch(document.annotationUrl).then(response => {
            if (response.status === 200) {
                response.text().then(xfdfString => {
                    instance.annotManager.importAnnotations(xfdfString);
                });
            }
        });
    }

    getAnnotationColor(annotation) {
        return annotation.Color;
        //return "rgb(255, 165, 0);";
    }

    processLoadedAnnotations(instance) {
        var annotationManager = instance.annotManager;
        var annotations = annotationManager.getAnnotationsList();

        var currentUserName = annotationManager.getCurrentUser();
        var users = [];
        var sharedAnnotationUsers = [];

        for (var i = 0; i < annotations.length; i++) {
            var annotation = annotations[i];

            if (currentUserName !== annotation.Author) {
                annotation.Hidden = true;

                if (users.indexOf(annotation.Author) < 0) {
                    users.push(annotation.Author);
                    sharedAnnotationUsers.push({
                        Name: annotation.Author,
                        Color: this.getAnnotationColor(annotation),
                        Selected: false
                    });
                }
            }
        }

        if (users.length > 0) {
            sharedAnnotationUsers.unshift({ Name: currentUserName, Color: null, Selected: true });
            instance.enableElements(['sharedAnnotationsButton']);
        } else {
            instance.disableElements(['sharedAnnotationsButton']);
        }

        annotationManager.drawAnnotationsFromList(annotations);
        this.setState({annotationUsers: sharedAnnotationUsers});
    }

    annotationsChanged(instance, annotations, action, event) {
        const { document } = this.props;

        if (event.imported) {
            this.processLoadedAnnotations(instance);
            return;
        }

        if (instance) {
            let url = "";
            if (action === 'add' || action === 'modify') {
                url = document.annotationSaveUrl;
            }
            else if (action === 'delete') {
                url = document.annotationDeleteUrl;
            }

            if (url !== "") {
                var annotationManager = instance.annotManager;
                annotationManager.exportAnnotations({ annotList: annotations, widgets: false, links: false, fields: false }).then((xfdfString) => {
                    fetch(url, {
                        method: 'POST',
                        body: xfdfString
                    });
                });
            }
        }
    }

    sharedAnnotationsClicked() {
        const { sharedAnnotationsOverlayVisible } = this.state;

        this.setState({sharedAnnotationsOverlayVisible: !sharedAnnotationsOverlayVisible});
    }

    updateShownAnnotations(user, shown) {
        const { instance } = this.state;

        if(instance) {
            var annotationManager = instance.annotManager;
            var annotations = annotationManager.getAnnotationsList();

            for (var j = 0; j < annotations.length; j++) {
                var annotation = annotations[j];

                if (user === annotation.Author) {
                    annotation.Hidden = !shown;
                }
            }

            annotationManager.drawAnnotationsFromList(annotations);
        }
    }

    render() {
        const { sharedAnnotationsOverlayVisible, annotationUsers } = this.state;

        return (
            <div className="webviewer">
                <div className="webviewer" id="webviewer-container" ref={this.viewer}></div>
                <SharedAnnotationsOverlay isOpen={sharedAnnotationsOverlayVisible} users={annotationUsers} userClicked={this.updateShownAnnotations} clickedOutside={this.sharedAnnotationsClicked} />
            </div>
        );
    }
}

PdfWebViewer.propTypes = propTypes;

function mapStateToProps(state) {
    const { loading, document } = state.content;

    return {
        loading,
        document
    }
}

export default connect(mapStateToProps)(PdfWebViewer);