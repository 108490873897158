import { ISAUTHENTICATING, SET_AUTHENTICATED, SET_AUTHENTICATE_ERROR_MESSAGE } from '../actions/actionTypes';

export default initialState => (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case ISAUTHENTICATING: 
            return {
                ...state,
                isAuthenticating: payload
            };
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: payload
            }
        case SET_AUTHENTICATE_ERROR_MESSAGE: 
            return {
                ...state,
                errorMessage: payload
            }
        default: 
            return state;
    }
}