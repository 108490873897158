import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SelectionButton from '../SelectionButton';
import getClassName from '../../helpers/getClassName';
import getOverlayPositionBasedOn from '../../helpers/getOverlayPositionBasedOn';
import setOnClickOutside from '../../helpers/setOnClickOutside';

import './SharedAnnotationsOverlay.scss';

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    userClicked: PropTypes.func.isRequired,
    clickedOutside: PropTypes.func.isRequired
};

class SharedAnnotationsOverlay extends PureComponent {
    constructor(props) {
        super(props);
        this.overlay = React.createRef();
        this.onClickOutside = this.onClickOutside.bind(this);
        this.onSelectionButtonClicked = this.onSelectionButtonClicked.bind(this);

        this.state = {
            left: 0,
            right: 'auto'
        }
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;

        if(isOpen && prevProps.isOpen === false) {
            this.setState(getOverlayPositionBasedOn('sharedAnnotationsButton', this.overlay));
            setOnClickOutside(true, this.onClickOutside);
        } else if( isOpen === false && prevProps.isOpen) {
            setOnClickOutside(false, this.onClickOutside);
        }
    }

    componentWillUnmount() {
        setOnClickOutside(false, this.onClickOutside);
    }

    onClickOutside(e) {
        const { clickedOutside } = this.props;

        if(e.target instanceof Element) {
            const overlayContainer = e.target.closest("[data-element=sharedAnnotationsOverlay]");
            if (overlayContainer) {
                return;
            }
        }

        clickedOutside();
        e.stopPropagation();
    }

    onSelectionButtonClicked(user, selected) {
        const { userClicked } = this.props;

        userClicked(user, selected);
    }

    render() {
        const { left, right } = this.state;
        const { users } = this.props;

        const className = getClassName('SharedAnnotationsOverlay', this.props);

        return (
            <div className={className} data-element="sharedAnnotationsOverlay" style={{ left, right }} ref={this.overlay}>
                {users.map(user => {
                    return <SelectionButton label={user.Name} selected={user.Selected} color={user.Color} onClick={this.onSelectionButtonClicked}></SelectionButton>;
                })}
            </div>
        );
    }
}

SharedAnnotationsOverlay.propTypes = propTypes;

export default SharedAnnotationsOverlay;