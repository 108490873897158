import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { API_URL } from '../../constants/settings';

import MarkdownViewer from '../MarkdownViewer';

const propTypes = {
    id: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

const AgendaItemMinutes = (props) => {
    const { id, t } = props;
    const [hasMinutes, setHasMinutes] = useState(false);
    const [minutes, setMinutes] = useState('');

    useEffect(() => {        
        fetch(`${API_URL}AgendaItem/Minutes/${id}`)
            .then(response => response.json())
            .then(json => {
                if(json.data) {
                    setHasMinutes(json.data.hasMinutes);
                    setMinutes(json.data.minutes);
                }
            });
    }, [id]);

    return (
        <>                
            {hasMinutes && (
                <>
                    <h3 className="label">{t('agenda.items.minutes')}</h3>
                    <MarkdownViewer text={minutes} className="minutes" />                
                </>
            )}
        </>
    );
}

AgendaItemMinutes.propTypes = propTypes;

export default withTranslation()(AgendaItemMinutes);