import React, { useEffect, useState } from 'react';

import { API_URL } from '../../constants/settings';

const ActionItems = (props) => {
    const { agendaId, agendaItemId } = props;

    const [actionItems, setActionItems] = useState([]);

    useEffect(() => {
        fetch(`${API_URL}AgendaItem/Actions/${agendaId}/${agendaItemId}`)
            .then(response => response.json())
            .then(json => {
                if(json.data) {
                    setActionItems(json.data.actions);
                }
            });
    }, [agendaItemId]);

    return (
        <>
            <div>Action items</div>
        </>
    )
}

export default ActionItems;