import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import DayView from '../DayView';
import { fetchAgendaList, clearAgenda } from '../../redux/actions/contentActions';

import './AgendaList.scss';

class AgendaListView extends PureComponent {
    componentDidMount() {
        const { fetchAgendas, resetAgenda } = this.props;
        fetchAgendas();

        resetAgenda();
    }

    render() {
        const { loading, agendaList, t } = this.props;

        return (
            <div className="agenda-list">
                {loading ? (<div className="spacing">{t('app.loading')}</div>) : (
                    <React.Fragment>
                        {agendaList.length === 0 ? 
                            (
                                <div className="spacing">{t('app.noMeetings')}</div>
                            ) : (
                                <React.Fragment>
                                    {agendaList.map((dayAgenda, index) => (
                                        <DayView item={dayAgenda} key={index} />
                                    ))}
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                )}
            </div>
        );
    }
}

AgendaListView.propTypes = {
    loading: PropTypes.bool.isRequired,
    agendaList: PropTypes.array.isRequired,
    fetchAgendas: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { loading, agendaList } = state.content;

    return {
        loading, 
        agendaList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAgendas: () => { dispatch(fetchAgendaList()) },
        resetAgenda: () => { dispatch(clearAgenda()) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AgendaListView)));