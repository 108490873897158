import React from 'react';
import { PropTypes } from 'prop-types';

const propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

const ActionLink = (props) => {
    const { link, text } = props;

    return (
        <a href={link} className="action-link">
            {text}
        </a>
    );
}

ActionLink.propTypes = propTypes;

export default ActionLink;