import { API_URL } from '../../constants/settings';
import { ISAUTHENTICATING, SET_AUTHENTICATED, SET_AUTHENTICATE_ERROR_MESSAGE } from './actionTypes';

export function isAuthenticating(authenticating) {
    return { type: ISAUTHENTICATING, payload: authenticating };
}

export function setAuthenticated(authenticated) {
    return { type: SET_AUTHENTICATED, payload: authenticated };
}

export function setAuthenticateErrorMessage(errorMessage) {
    return { type: SET_AUTHENTICATE_ERROR_MESSAGE, payload: errorMessage };
}

export function isAuthenticated() {
    return (dispatch) => {
        return fetch(`${API_URL}Authenticate/IsAuthenticated`)
            .then(response => response.json())
            .then(json => {
                if(json.data.authenticated) {
                    dispatch(setAuthenticated(true));
                }

                dispatch(isAuthenticating(false));
            });
    }
}