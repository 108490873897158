import { MenuOptions } from '../models/MenuOptions';

export default {
    menu: {
        menuItems: [],
        activeMenu: MenuOptions.MAIN,
        backButton: {
            show: false,
            text: ''
        },
        parameters: {
            selectedAgendatype: {
                id: '',
                name: ''
            },
            selectedYear: '',
            selectedAgenda: {
                id: '',
                name: ''
            }
        }
    },
    breadcrumb: {
        breadcrumbItems: []
    },
    content: {
        loading: false,
        loaded: false,
        noteSaving: false,
        agendaList: [],
        agenda: {},
        document: {}
    },
    authenticate: {
        isAuthenticating: true,
        authenticated: false,
        errorMessage: ''
    }
}