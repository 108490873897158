import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import AgendaHeaderProperty from '../AgendaHeaderProperty';

const propTypes = {
    agenda: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

const defineProperties = (agenda, t) => {
    const { time, location, chairman, description } = agenda;

    let properties = [];
    properties.push({
        label: t('agenda.time'),
        value: time,
        isFormatted: false
    });

    if(location) {
        properties.push({
            label: t('agenda.location'),
            value: location,
            isFormatted: false
        });
    }

    if(chairman) {
        properties.push({
            label: t('agenda.chairman'),
            value: chairman,
            isFormatted: false
        });
    }

    if(description) {
        properties.push({
            label: t('agenda.description'),
            value: description,
            isFormatted: true
        });
    }

    return properties;
}

const AgendaHeader = (props) => {
    const { agenda, t } = props;

    const properties = defineProperties(agenda, t);

    return (
        <div className="header">
            <div className="title">
                {agenda.title}
            </div>
            <div className="details">
                {properties.length > 0 && (
                    properties.map((property, index) => (
                        <AgendaHeaderProperty {...property} key={index} />
                    ))
                )}
            </div>
        </div>
    );
}

AgendaHeader.propTypes = propTypes;

export default withTranslation()(AgendaHeader);