import { CONTENT_LOADING, SET_AGENDA_LIST, SET_AGENDA, SET_DOCUMENT, SET_NOTE_SAVING } from '../actions/actionTypes';

export default initialState => (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case CONTENT_LOADING:
            return {
                ...state,
                loading: payload
            };
        case SET_AGENDA_LIST: 
            return {
                ...state,
                agendaList: payload
            };
        case SET_AGENDA:
            return {
                ...state,
                agenda: payload
            };
        case SET_DOCUMENT: 
            return {
                ...state,
                document: payload
            }
        case SET_NOTE_SAVING:
            return {
                ...state,
                noteSaving: payload
            }
        default: 
            return state;
    }
}