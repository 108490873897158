import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { addBreadcrumbItem, removeBreadcrumbItem } from '../../redux/actions/breadcrumbActions';
import { BreadcrumbItemType } from '../../models/BreadcrumbItemType';

import BreadcrumbItem from "../BreadcrumbItem";

import './Breadcrumb.scss';

class Breadcrumb extends PureComponent {
    componentDidUpdate(prevProps) {
        const { agenda, document, setBreadcrumbItem, unsetBreadcrumbItem } = this.props;

        if(prevProps.agenda.id !== agenda.id) {
            if(agenda.id) {
                setBreadcrumbItem(agenda.id, agenda.dateString, BreadcrumbItemType.AGENDA);
            } else  {
                unsetBreadcrumbItem(BreadcrumbItemType.AGENDA);
            }
        }
        if(prevProps.document.id !== document.id) {
            if(document.id) {
                setBreadcrumbItem(document.id, document.title, BreadcrumbItemType.DOCUMENT);
            } else {
                unsetBreadcrumbItem(BreadcrumbItemType.DOCUMENT);
            }
        }
    }

    render() {
        const { breadcrumbItems } = this.props;

        return (
            <div className='app-breadcrumb'>
                <div className='breadcrumb-item'>
                    <Link to='/'>Start</Link>
                </div>
                {breadcrumbItems.map((item, index) => (
                    <BreadcrumbItem key={index} {...item} />
                ))}
            </div>
        );
    }
}

Breadcrumb.propTypes = {
    breadcrumbItems: PropTypes.array.isRequired,
    agenda: PropTypes.object,
    document: PropTypes.object,
    setBreadcrumbItem: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    const { breadcrumbItems } = state.breadcrumb;
    const { agenda, document } = state.content;

    return {
        breadcrumbItems,
        agenda,
        document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumbItem: (id, title, type) => { dispatch(addBreadcrumbItem(id, title, type)) },
        unsetBreadcrumbItem: (type) => { dispatch(removeBreadcrumbItem(type)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Breadcrumb));