import { API_URL } from '../../constants/settings';
import { SET_ACTIVE_MENU, SET_BACKBUTTON, SET_MENUITEMS, SET_SEARCHVALUE, SET_MENU_PARAMETERS } from './actionTypes';
import { MenuOptions } from '../../models/MenuOptions';

export function setBackButton(backButton) {
    return { type: SET_BACKBUTTON, payload: backButton };
}

export function setActiveMenu(menuOption) {
    return { type: SET_ACTIVE_MENU, payload: menuOption };
}

export function setParameters(args) {
    return { type: SET_MENU_PARAMETERS, payload: args };
}

export function setMenuItems(menuItems) {
    return { type: SET_MENUITEMS, payload: menuItems };
}

export function setSearchValue(searchTerm) {
    return { type: SET_SEARCHVALUE, payload: searchTerm };
}

export function retrieveMenuItems() {
    return (dispatch, getState) => {
        const state = getState();
        const { activeMenu, backButton, parameters } = state.menu;
        const { selectedAgendatype, selectedYear } = parameters;
        let menuExtension = '';

        switch(activeMenu) {
            case MenuOptions.AGENDATYPE:
                backButton.show = true;
                backButton.text = 'app.start';
                menuExtension = `Years/${selectedAgendatype.id}`;
                break;
            case MenuOptions.YEAR:
                backButton.show = true;
                backButton.text = 'app.years';
                menuExtension = `AgendaDates/${selectedAgendatype.id}/${selectedYear}`;
                break;
            case MenuOptions.MAIN:
                backButton.show = false;
                backButton.text = '';
                menuExtension = 'Agendatypes';
                break;
            default: 
                return;
        }       

        return fetch(`${API_URL}menu/${menuExtension}`)
            .then(response => response.json())
            .then(json => {
                if(json.data.menuItems) {
                    dispatch(setMenuItems(json.data.menuItems));
                }
                dispatch(setBackButton(backButton));
            });
    }
}

export function menuNavigation(clickedMenuItem, history) {
    return dispatch => {
        if(clickedMenuItem !== null) {
            dispatch(navigateForward(clickedMenuItem, history));
        } else {
            dispatch(navigateBack());
        }
    }
}

export function updateActiveMenuItem(id) {
    return (dispatch, getState) => {
        const state = getState();
        const { menuItems, activeMenu } = state.menu;

        if(activeMenu !== MenuOptions.YEAR) {
            return;
        }

        var newMenuItems = [...menuItems];
        
        const activeMenuItem = newMenuItems.find(item => {
            return item.isActive;
        });
        if(activeMenuItem) {
            activeMenuItem.isActive = false;
        }
        const menuItem = newMenuItems.find(item => {
            return item.id === id;
        });
        if(menuItem) {
            menuItem.isActive = true;
        }

        dispatch(setMenuItems(newMenuItems));
    }
}

export function setDocumentMenu() {
    return (dispatch, getState) => {
        const state = getState();
        const { document } = state.content;

        if(document.id) {
            dispatch(setAgendaMenu());
        } else {
            dispatch(resetMenu());
        }
    }
}

function setAgendaMenu() {
    return (dispatch, getState) => {
        const state = getState();
        const { agenda, document } = state.content;
        let { backButton } = state.menu;

        const menuItems = [];

        if(agenda.documents) {
            for(var i = 0; i < agenda.documents.length; i++) {
                const doc = agenda.documents[i];

                let menuItem = {
                    id: doc.id,
                    title: doc.title,
                    subTitle: '',
                    type: MenuOptions.DOCUMENT,
                    isActive: doc.id === document.id,
                    showAnnotationsIcon: doc.hasAnnotations,
                    showSharedAnnotationsIcon: doc.hasSharedAnnotations
                };

                menuItems.push(menuItem);
            }
        }

        if(agenda.agendaItems) {
            for(var x = 0; x < agenda.agendaItems.length; x++) {
                const agendaItem = agenda.agendaItems[x];

                let menuItem = {
                    id: agendaItem.id,
                    title: agendaItem.title,
                    subTitle: '',
                    type: MenuOptions.AGENDAITEM,
                    isActive: false,
                    showAnnotationsIcon: false,
                    showSharedAnnotationsIcon: false
                };

                menuItems.push(menuItem);

                if(agendaItem.documents) {
                    for(var j = 0; j < agendaItem.documents.length; j++) {
                        const doc = agendaItem.documents[j];

                        let docMenuItem = {
                            id: doc.id,
                            title: doc.title,
                            subTitle: '',
                            type: MenuOptions.DOCUMENT,
                            isActive: doc.id === document.id,
                            showAnnotationsIcon: doc.hasAnnotations,
                            showSharedAnnotationsIcon: doc.hasSharedAnnotations
                        };

                        menuItems.push(docMenuItem);
                    }
                }
            }
        }

        backButton.show = false;
        backButton.text = '';
        dispatch(setMenuItems(menuItems));
        dispatch(setBackButton(backButton))
        dispatch(setActiveMenu(MenuOptions.DATE));
    };
}

function resetMenu() {
    return dispatch => {
        dispatch(setActiveMenu(MenuOptions.MAIN));
        dispatch(retrieveMenuItems());
    }
}

function performMenuUpdate(menuItem) {
    return (dispatch, getState) => {
        const state = getState();
        const { parameters } = state.menu;
        
        switch(menuItem.type) {
            case MenuOptions.AGENDATYPE:
                parameters.selectedAgendatype.id = menuItem.id;
                parameters.selectedAgendatype.name = menuItem.title;
                break;
            case MenuOptions.YEAR:
                parameters.selectedYear = menuItem.id;
                break;
        }

        dispatch(setActiveMenu(menuItem.type));
        dispatch(setParameters(parameters));
        dispatch(retrieveMenuItems());
    }
}

function performNavigation(menuItem, history) {
    const { id, type } = menuItem;
    let url = '';
    if(type === MenuOptions.DATE) {
        url = `/agenda/${id}`;
    } else if(type === MenuOptions.DOCUMENT) {
        url = `/document/${id}`;
    }

    history.push(url);
}

function navigateForward(clickedMenuItem, history) {
    return dispatch => {
        switch(clickedMenuItem.type) {
            case MenuOptions.MAIN:
            case MenuOptions.AGENDATYPE:
            case MenuOptions.YEAR:
                dispatch(performMenuUpdate(clickedMenuItem));
            case MenuOptions.DATE:
            case MenuOptions.DOCUMENT:
                performNavigation(clickedMenuItem, history);
                return;
            default:
                return;
        }
    }
}

function navigateBack() {
    return (dispatch, getState) => {
        const state = getState();
        const { activeMenu, parameters } = state.menu;

        const newActiveMenu = activeMenu - 1;

        switch(activeMenu) {
            case MenuOptions.YEAR: 
                parameters.selectedYear = '';
                break;
            case MenuOptions.AGENDATYPE:
                parameters.selectedAgendatype.id = '';
                parameters.selectedAgendatype.name = '';
                break;
            default:
                break;
        }

        dispatch(setActiveMenu(newActiveMenu));
        dispatch(setParameters(parameters));
        dispatch(retrieveMenuItems());
    }
}





















export function doSearch(searchTerm) {
    return (dispatch, getState) => {
        const state = getState();
        const { activeMenu, parameters } = state.menu;
        const { selectedAgendatype, selectedYear } = parameters;

        if(searchTerm !== "") {
            let url = `${API_URL}menu/search/${searchTerm}`;
            if(activeMenu === MenuOptions.AGENDATYPE) {
                url = `${url}/${selectedAgendatype.id}`;
            }
            if(activeMenu === MenuOptions.YEAR) {
                url = `${url}/${selectedAgendatype.id}/${selectedYear}`;
            }

            return fetch(url)
                .then(response => response.json())
                .then(json => {
                    if(json.data.menuItems) {
                        dispatch(setMenuItems(json.data.menuItems));
                    }
                    // dispatch(setBackButton(backButton))
                    // dispatch(setActiveMenu(menuItem.type));
                    // dispatch(setParameters(parameters));
                });
        } else {
            dispatch(retrieveMenuItems());
        }
    }
}