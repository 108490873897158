import whitespace from 'is-whitespace-character';
import locate from '../locate/insert';

const plus = '+';
const fence = '++';

function underline(eat, value, silent) {
    var self = this
    var character = ''
    var previous = ''
    var preceding = ''
    var subvalue = ''
    var index
    var length
    var now
  
    if (
      !self.options.gfm ||
      value.charAt(0) !== plus ||
      value.charAt(1) !== plus ||
      whitespace(value.charAt(2))
    ) {
      return
    }
  
    index = 1
    length = value.length
    now = eat.now()
    now.column += 2
    now.offset += 2
  
    while (++index < length) {
      character = value.charAt(index)
  
      if (
        character === plus &&
        previous === plus &&
        (!preceding || !whitespace(preceding))
      ) {
        /* istanbul ignore if - never used (yet) */
        if (silent) {
          return true
        }
  
        return eat(fence + subvalue + fence)({
          type: 'insert',
          children: self.tokenizeInline(subvalue, now)
        })
      }
  
      subvalue += previous
      preceding = previous
      previous = character
    }
}
underline.locator = locate;

export default underline;